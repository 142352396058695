<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <filter-interface
      v-if="!loading && permissions.read"
      :model="model"
      :nav="nav"
      :pageName="pageName"
      :rows="rows"
      :searchProperties="searchProperties"
      :staticDataProperties="staticDataProperties"
      :staticData="staticData"
      :loading="loading"
      :permissions="permissions"
      :referenceData="referenceData"
      :sortDirectionProp="sortDirection"
      :sortPropertyProp="sortProperty"
      :sortPropertyOptionsProp="sortPropertyOptions"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterface.vue'
import workflow from '@/workflows/trainingcase'

export default {
  name: 'TrainingcaseLayout',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.trainingcases
      },
      set (payload) {
        this.$store.commit('setTrainingcases', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.read = ac.can(this.user.acgroups).readAny('trainingcase').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('trainingcase').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      model: 'trainingcase',
      nav: [
        { name: 'Trainingcases', text: 'Inventory' },
        { name: 'TrainingcasesPlayer', text: 'Player' },
        { name: 'TrainingcasesDataStrats', text: 'Data stratification' }
      ],
      pageName: 'Training Cases',
      permissions: {
        add: false,
        read: false
      },
      referenceData: [
      ],
      searchProperties: ['name', 'body'],
      staticDataProperties: [
        { name: 'Controls risk', property: 'controlrisks', graph: 'controlrisks' },
        { name: 'MAR risk', property: 'risks', graph: 'risks' },
        { name: 'Workflow', property: 'workflow', graph: 'workflow' }
      ],
      sortDirection: 'desc',
      sortProperty: 'created_at',
      sortPropertyOptions: [
        { value: 'name', text: 'title' },
        { value: 'created_at', text: 'date created' }
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        const staticData = {}
        staticData.workflow = workflow
        _.each(['controlrisks', 'risks'], p => {
          let set = []
          _.each(this.rows, row => {
            _.each(row[p], x => {
              if (x) {
                set.push(x)
              }
            })
          })
          set = _.uniqBy(set, 'id')
          set = _.sortBy(set, ['name'])
          staticData[p] = set
        })
        this.staticData = staticData
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/trainingcases/limit/${limit}/offset/${offset}`)
          response.forEach(x => {
            const w = workflow.find(y => y.id === x.workflow_id)
            x.workflow = [{
              id: x.workflow_id,
              name: w.name
            }]
          })
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
